<template>
    <div :class="{ 'form-loading': loading }">
        <window-header></window-header>
        <nav style="justify-content: space-between;">
            <button class="button button-fill" @click="abrirConfiguracion">
                <fa-icon icon="filter" /><span>Filtros</span>
            </button>
            <button class="button button-fill" @click="guardar">
                <fa-icon icon="save" /> <span>Guardar</span>
            </button>
        </nav>
        <section>
            <article style="height: 536px; width: 975px">
                <div ref="hotTableComponent"></div>
            </article>
        </section>
    </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import Handsontable from "handsontable";

export default {
    mixins: [WindowMixin],
    data: function () {
        return {
            title: "Familias activas por representante",
            hotInstance: null,
            loading: false
        };
    },
    methods: {
        guardar() {
            let self = this;
            let data = self.hotInstance.getSourceData().filter(d => d.changed)
            if (!data.length) {
                self.app.toast("No se ha modificado ninguna línea", "error")
                return;
            }
            let p = Promise.resolve();
            data.forEach(item => {
                let representantes_activos = Object.entries(item.representantes_activos).filter(x => x[1]).map(x => x[0])
                p = p.then(window.DB.put('familia', { itemId: item.codigo, data: { representantes_activos: representantes_activos?.length ? representantes_activos : false } }));
            })
            p
                .catch(e => self.app.toast("Error al actualizar las familias", "error"))
                .then(_ => {
                    self.app.toast("Familias actualizadas", "success")
                    // Actualizar representantes
                    self.loading = true;
                    window.DB.getList('representante', { filter: ['activo', '=', 1], order: 'codigo', fields: ['codigo', { name: 'familias_activas', fields: ['codigo'] }] }).then(resRep => {
                        self.resRepData = resRep.data;
                    }).finally(_ => self.loading = false)
                })
        },
        abrirConfiguracion() {
            var self = this;
            self.app.openChildWindow("familias_activas_config", self.win, {
                backdrop: true,
                //model: self.model,
                props: {
                    parent: self,
                },
            });
        },
    },
    mounted() {
        var self = this;

        self.resRepData = [];

        self.filtro = null;

        self.actualizarFamilias = (forceVal) => {
            self.loading = true;
            let filter = [];//[['activo', '=', 1]];
            if (self.filtro) filter.push(self.filtro);
            window.DB.getList('familia', { filter, order: 'codigo', fields: ['codigo', 'nombre'] }).then(resArt => {
                let data = resArt.data.map(a => ({ ...a, ...{ representantes_activos: self.resRepData.reduce((acc, r) => ({ ...acc, ...{ [r.codigo]: typeof forceVal == 'undefined' ? (r.familias_activas.some(q => q.codigo == a.codigo) ? 1 : 0) : forceVal } }), {}), changed: typeof forceVal != 'undefined' } }));
                self.hotInstance.loadData(data);
            }).finally(_ => self.loading = false)
        }


        window.DB.getList('representante', { filter: ['activo', '=', 1], order: 'codigo', fields: ['codigo', { name: 'familias_activas', fields: ['codigo'] }] }).then(resRep => {
            self.resRepData = resRep.data;
            const rowHeaderWidth = 40;
            const checkboxWidth = 35;
            const codigoWidth = 100;
            const descripcionWidth = Math.max(975 - rowHeaderWidth - 10 - codigoWidth - resRep.data.length * checkboxWidth, 200);
            const colHeaders = ['Código', 'Descripción', ...resRep.data.map(r => r.codigo)];
            const colWidths = checkboxWidth;
            const checkbox = { type: "checkbox", checkedTemplate: 1, uncheckedTemplate: 0, allowInvalid: false, allowEmpty: false, className: 'text-align-center' };
            let columns = [
                {
                    data: 'codigo',
                    readOnly: true,
                    width: codigoWidth,
                    renderer: function (hotInstance, td, row, column, prop, value, cellProperties) {
                        Handsontable.renderers.TextRenderer.apply(this, arguments);
                        let r = self.hotInstance?.toPhysicalRow(row) || row;
                        if (hotInstance.getSourceDataAtRow(r).changed) td.style.backgroundColor = '#fff3bb';
                    },
                    columnSorting: {
                        compareFunctionFactory: function compareFunctionFactory(sortOrder, columnMeta) {
                            return function comparator(a, b) {
                                //window.console.log(a, b, sortOrder, (a[order] > b[order] ? 1 : (a[order] < b[order] ? -1 : 0)) * (sortOrder == 'asc' ? 1 : -1))
                                return (a > b ? 1 : (a < b ? -1 : 0)) * (sortOrder == 'asc' ? 1 : -1);
                            };
                        }
                    }
                },
                { data: 'nombre', readOnly: true, width: descripcionWidth },
                ...resRep.data.map(r => ({ ...{ data: 'representantes_activos.' + r.codigo }, ...checkbox }))
            ];

            self.hotInstance = new Handsontable(self.$refs.hotTableComponent, {
                licenseKey: 'non-commercial-and-evaluation',
                //autoRowSize: { syncLimit: 300 },
                dataSchema: { codigo: null, nombre: null, representantes_activos: {} },
                multiSelect: true,
                rowHeaders: true,
                width: 975,
                height: 535,
                columnSorting: true,
                sortIndicator: true,
                manualColumnResize: true,
                manualColumnMove: true,
                fillHandle: {
                    //direction: 'vertical',
                    autoInsertRow: false,
                },
                columns,
                colHeaders,
                colWidths,
                data: [],
                minRows: 0,
                minSpareRows: 0,
                beforeChange: (changes, source) => changes.forEach(c => changes.push([c[0], 'changed', null, 1])),
                rowHeights: 21,
                enterBeginsEditing: false,
                autoWrapCol: false,
                autoWrapRow: false,
                rowHeaderWidth,
            });
            self.actualizarFamilias();
        })
    },
};
</script>
<style>
.text-align-center {
    text-align: center;
}
</style>